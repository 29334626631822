<template>
  <div class="mail">
    <div class="mail-header flex-container">
      <img
        src="@image/shop/left_return_icon.png"
        @click="$router.go(-1)"
        class="return"
      />
      <p class="flex1">{{ getMailStatus(mail.deliverystatus) }}</p>
      <div class="kefu flex-container" @click="chatBoo = true">
        <img src="@image/order/order_kefui.png" />
        <span>联系客服</span>
      </div>
    </div>
    <div style="padding-bottom:20px;">
      <div class="mail-buss">
        <div class="m-b-info flex-container">
          <img :src="mail.logo" style="width:50px" />
          <div class="info">
            <p>{{ mail.expName }}</p>
            <span
              >官方电话：<a :href="`tel:${mail.expPhone}`">{{
                mail.expPhone
              }}</a></span
            >
          </div>
        </div>
        <div class="m-b-no" @click="doCopy(mail.number)">
          <span>{{ mail.expName }} </span>
          <span> {{ mail.number }}</span>
          <b>复制 </b>
        </div>
      </div>

      <div class="mail-step">
        <van-steps direction="vertical" :active="0">
          <van-step v-for="(item, index) in mail.list" :key="index">
            <h3>{{ item.status }}</h3>
            <p>{{ item.time }}</p>
          </van-step>
        </van-steps>
      </div>
    </div>
    <!-- <template v-else>
      <div class="mail-error">
        <div>1. {{ getMailerror(mail.ret_code) }}</div>
        <div>2. {{ mail.msg }}</div>
        <span>有问题请联系客服</span>
      </div>
    </template> -->

    <oChat :boo.sync="chatBoo"></oChat>
  </div>
</template>

<script>
import { post } from "@get/http";
import { Step, Steps, Toast } from "vant";
import oChat from "@view/me/view/chat/popup.vue";
export default {
  components: {
    vanStep: Step,
    vanSteps: Steps,
    oChat,
  },
  data() {
    return {
      mail: {},
      chatBoo: false,
    };
  },
  async created() {
    try {
      let result = await post("/v3.MemberLogistics/getInfo", {
        data: {
          com: "auto",
          nu: this.$route.query?.mailid,
          phone: this.$route.query?.phone,
        },
      });
      if (result) {
        this.mail = result;
      }
    } catch (error) {
      console.err(error);
    }
  },
  methods: {
    doCopy: function(copy) {
      this.$copyText(copy).then(
        function(e) {
          Toast("微信:已复制");
        },
        function(e) {
          Toast("微信:复制失败");
        }
      );
    },
    getMailerror(val) {
      if (val == 1) {
        return "输入参数错误";
      } else if (val == 2) {
        return " 查不到物流信息";
      } else if (val == 3) {
        return "单号不符合规则";
      } else if (val == 4) {
        return "快递公司编码不符合规则";
      } else if (val == 5) {
        return "快递查询渠道异常";
      } else if (val == 6) {
        return " 未查到单号对应的快递公司";
      } else if (val == 7) {
        return "单号与手机号不匹配";
      } else {
        return "接口调用失败";
      }
    },
    getMailStatus(val) {
      if (val == 0) {
        return "快递收件(揽件)";
      } else if (val == 1) {
        return "在途中 ";
      } else if (val == 2) {
        return "正在派件";
      } else if (val == 3) {
        return "已签收";
      } else if (val == 4) {
        return "派送失败";
      } else if (val == 5) {
        return "疑难件";
      } else if (val == 6) {
        return "退件签收";
      } else {
        return "";
      }
    },
    formatterDateTime() {
      var date = new Date();
      var month = date.getMonth() + 1;
      var datetime =
        date.getFullYear() +
        "" + // "年"
        (month >= 10 ? month : "0" + month) +
        "" + // "月"
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
        "" +
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
        "" +
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        "" +
        (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());
      return datetime;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.mail {
  background: #f5f5f5;
}
.mail-header {
  box-shadow: rem(1) 0px rem(9) rem(-6) #333333;
  width: rem(710);
  border-radius: rem(16);
  background: #fff;
  margin: 0 auto;
  height: rem(100);
  z-index: 9899;
  position: fixed;
  top: rem(20);
  left: 0;
  right: 0;

  align-items: center;
  > .return {
    width: rem(20);
    height: rem(35);
    display: block;
    margin-left: rem(50);
  }
  > .kefu {
    margin: 0 rem(30);
    margin-left: rem(50);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    img {
      width: rem(50);
    }
  }
  > p {
    font-size: rem(30);
    margin-left: rem(30);
    width: rem(150);
    border-right: 1px solid grey;
  }
}
.mail-step {
  width: rem(710);
  background: #fff;
  margin: 0 auto;
  // padding-bottom: rem(30);
  margin-top: rem(30);
  border-radius: rem(16);
  .van-steps {
    border-radius: rem(16);
  }
}

.mail-buss {
  width: rem(710);
  margin: 0 auto;
  background: #fff;
  margin-top: rem(150);
  border-radius: rem(16);
  padding: rem(30);
  box-sizing: border-box;
  .m-b-info {
    align-items: center;

    > img {
      width: rem(250);
    }
    > .info {
      margin-left: rem(20);
      p {
        font-size: rem(32);
      }
    }
  }
  .m-b-no {
    padding-top: rem(50);
    b {
      font-size: rem(10);

      margin-left: rem(10);
      // width: rem(80);
      text-align: center;

      display: inline-block;
      color: #ebebeb;
      // color: #333333;
    }
  }
  // padding-top: rem(150);
}

.mail-error {
  width: rem(710);
  margin: 0 auto;
  background: #fff;
  border-radius: rem(16);
  margin-top: rem(150);
  box-sizing: border-box;
  padding: rem(30);
  > span {
    display: block;
    margin-top: rem(10);
    color: grey;
  }
}
</style>
